import React from "react"

import Layout from "@components/Layout"
import SEO from "@components/SEO"

import CovidPromise from "@components/CovidPromise"
// import FeaturedStudios from "@components/FeaturedStudios"
// import FeaturedStyle from "@components/FeaturedStyle"
// import FeaturedArtists from "@components/FeaturedArtists"
// import StylesCommon from "@components/StylesCommon"
// import LatestArticles from "@components/LatestArticles"
// import InstagramFeed from "@components/InstagramFeed"
import Splash from "@components/Home/Splash"
import About from "@components/Home/About"
// import Testamonials from "@components/Home/Testamonials"
import AsSeenIn from "@components/Home/AsSeenIn"
import { useTranslation } from "@hooks"

const Home = () => {
  const [t] = useTranslation()
  return (
    <Layout>
      <SEO title={t("pages.Home.heading")} titleTemplate={false} />
      <CovidPromise />
      <Splash />
      <AsSeenIn />
      <About />
      {/* <FeaturedArtists />
      <FeaturedStyle />
      <FeaturedStudios />
      <StylesCommon />
      <LatestArticles />
      <Testamonials />
      <InstagramFeed /> */}
    </Layout>
  )
}

export default Home
