import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import PropTypes from "prop-types"

const AppScreen = ({ alt, style, imgStyle }) => {
  const data = useStaticQuery(graphql`
    query {
      img: file(relativePath: { eq: "explore-iynk.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            # aspectRatio
            # ...GatsbyImageSharpFluid // Blur up
            ...GatsbyImageSharpFluid_noBase64
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `)

  return (
    <Img
      fluid={data.img.childImageSharp.fluid}
      style={style} /* styles for the wrapper container */
      imgStyle={imgStyle} /* Styles for the actual img element */
      alt={alt}
      className={"img--rounded"}
    />
  )
}

AppScreen.defaultProps = {
  style: {},
  imgStyle: {},
  alt: "An image of IYNK",
}

AppScreen.propTypes = {
  style: PropTypes.object.isRequired,
  imgStyle: PropTypes.object.isRequired,
  alt: PropTypes.string.isRequired,
}

export default AppScreen
