import React from "react"
import classnames from "classnames"
// import { Link } from "gatsby"

import IynkVideo from "@assets/videos/iynk-own.mp4"
import { useTranslation } from "@hooks"
// import * as ROUTES from "@constants/routes"

import Container from "react-bulma-components/lib/components/container"
import Heading from "react-bulma-components/lib/components/heading"
import Section from "react-bulma-components/lib/components/section"
import Columns from "react-bulma-components/lib/components/columns"
import Content from "react-bulma-components/lib/components/content"
import Card from "react-bulma-components/lib/components/card"
// import Button from "react-bulma-components/lib/components/button"

import styles from "./styles.module.scss"

const About = () => {
  const [t] = useTranslation()
  return (
    <Section className={classnames(styles.About, "has-background-black")}>
      <Container>
        <Columns breakpoint="mobile" className={"is-vcentered"}>
          <Columns.Column
            mobile={{
              size: 12,
            }}
            tablet={{
              size: 6,
            }}
            desktop={{
              size: 6,
            }}
            widescreen={{
              size: 6,
            }}
            fullhd={{
              size: 6,
            }}
            // className={"is-hidden-touch"}
          >
            <Card
              className={classnames(
                "is-card-equal-height",
                "has-background-black"
                // "is-hidden-touch"
              )}
              shadowless
            >
              <Card.Content paddingless>
                <video playsInline autoPlay muted loop>
                  <source src={IynkVideo} type="video/mp4" />
                </video>
              </Card.Content>
            </Card>
          </Columns.Column>
          <Columns.Column
            mobile={{
              size: 12,
            }}
            tablet={{
              size: 6,
            }}
            desktop={{
              size: 6,
            }}
            widescreen={{
              size: 6,
            }}
            fullhd={{
              size: 6,
            }}
            className="has-text-centered-touch"
          >
            <Card
              className={classnames(
                "is-card-equal-height",
                "has-background-black"
              )}
              shadowless
            >
              <Card.Content>
                <Content>
                  <Heading
                    className={classnames("is-uppercase", "has-text-white")}
                    renderAs={"h2"}
                  >
                    {t("components.About.title")}
                  </Heading>
                  {/* <p className={classnames("has-text-white")}>
                    {t("components.About.description1")}
                  </p> */}
                  {/* <p className={classnames("has-text-white")}>
                    {t("components.About.description2")}
                  </p> */}
                  <p className={classnames("has-text-white")}>
                    {t("components.About.description3")}
                  </p>
                  <p className={classnames("has-text-white")}>
                    {t("components.About.description4")}
                  </p>
                  {/* <Link to={ROUTES.APP}>
                    <Button
                      className={classnames(
                        "has-background-white",
                        "has-text-black"
                      )}
                    >
                      {t("components.About.button")}
                    </Button>
                  </Link> */}
                </Content>
              </Card.Content>
            </Card>
          </Columns.Column>
        </Columns>
      </Container>
    </Section>
  )
}

export default About
