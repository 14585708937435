import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"

const TheMirror = ({ alt, style }) => {
  const data = useStaticQuery(graphql`
    query {
      img: file(relativePath: { eq: "the-mirror-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 200, quality: 100, grayscale: true) {
            aspectRatio
            # ...GatsbyImageSharpFluid // With blur-up
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <img src={data.img.childImageSharp.fluid.src} style={style} alt={alt} />
  )
}

TheMirror.defaultProps = {
  style: {
    maxHeight: "50px",
  },
  alt: "The Mirror Logo",
}

TheMirror.propTypes = {
  style: PropTypes.object.isRequired,
  alt: PropTypes.string.isRequired,
}

export default TheMirror
