import React from "react"
import classnames from "classnames"
// import { Link } from "gatsby"

import AppScreen from "./AppScreen"

import { useTranslation } from "@hooks"
// import * as ROUTES from "@constants/routes"
import DownloadApple from "@components/DownloadApple"
import DownloadGoogle from "@components/DownloadGoogle"

import Card from "react-bulma-components/lib/components/card"
import Container from "react-bulma-components/lib/components/container"
import Heading from "react-bulma-components/lib/components/heading"
import Section from "react-bulma-components/lib/components/section"
import Columns from "react-bulma-components/lib/components/columns"
import Content from "react-bulma-components/lib/components/content"
// import Button from "react-bulma-components/lib/components/button"

import styles from "./styles.module.scss"

const Splash = () => {
  const [t] = useTranslation()

  return (
    <Section className={classnames(styles.Splash, "has-background-white")}>
      <Container>
        <Columns breakpoint="mobile" className={classnames("is-vcentered")}>
          <Columns.Column
            mobile={{
              size: 12,
            }}
            tablet={{
              size: 6,
            }}
            desktop={{
              size: 6,
            }}
            paddingless
            className="has-text-centered-touch"
          >
            <Card className={classnames("is-card-equal-height")} shadowless>
              <Card.Content>
                <Content>
                  <Heading
                    className={classnames(styles.Splash__title, "is-uppercase")}
                  >
                    {t("components.Splash.title")}
                  </Heading>
                  <p>{t("components.Splash.description")}</p>
                  {/* <Link to={ROUTES.TATTOOS}>
                    <Button
                      rounded
                      color="danger"
                      size={"medium"}
                      className={classnames("has-text-white-bis")}
                    >
                      {t("components.Splash.button")}
                    </Button>
                  </Link> */}
                  <Container>
                    <Columns
                      breakpoint="mobile"
                      className={classnames(
                        styles.Splash__download,
                        "is-vcentered"
                      )}
                    >
                      <Columns.Column
                        mobile={{
                          size: 4,
                        }}
                        tablet={{
                          size: 4,
                        }}
                        desktop={{
                          size: 5,
                        }}
                      >
                        <DownloadApple />
                      </Columns.Column>
                      <Columns.Column
                        mobile={{
                          size: 4,
                        }}
                        tablet={{
                          size: 4,
                        }}
                        desktop={{
                          size: 5,
                        }}
                      >
                        <DownloadGoogle />
                      </Columns.Column>
                    </Columns>
                  </Container>
                </Content>
              </Card.Content>
            </Card>
          </Columns.Column>
          <Columns.Column
            mobile={{
              size: 12,
            }}
            tablet={{
              size: 6,
            }}
            desktop={{
              size: 6,
            }}
            paddingless
          >
            <Card className={classnames("is-card-equal-height")} shadowless>
              <AppScreen />
            </Card>
          </Columns.Column>
        </Columns>
      </Container>
    </Section>
  )
}

export default Splash
