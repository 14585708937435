import React from "react"
import classnames from "classnames"

import Container from "react-bulma-components/lib/components/container"
import Heading from "react-bulma-components/lib/components/heading"
import Section from "react-bulma-components/lib/components/section"
import EveningStandard from "./EveningStandard"
import TheSun from "./TheSun"
import NowThis from "./NowThis"
import TheMirror from "./TheMirror"
import Stuff from "./Stuff"
import { useTranslation } from "@hooks"

import styles from "./styles.module.scss"

const AsSeenIn = () => {
  const [t] = useTranslation()
  return (
    <Section
      className={classnames(styles.AsSeenIn, "has-background-white-ter")}
    >
      <Container>
        <Heading
          subtitle
          size={4}
          renderAs={"h3"}
          className={"has-text-centered"}
        >
          {t("components.AsSeenIn.heading")}
        </Heading>
        <div className={classnames(styles.AsSeenIn__logos)}>
          <div className={classnames(styles.AsSeenIn__logo, "is-inline-block")}>
            <TheMirror />
          </div>
          <div className={classnames(styles.AsSeenIn__logo, "is-inline-block")}>
            <EveningStandard />
          </div>
          <div className={classnames(styles.AsSeenIn__logo, "is-inline-block")}>
            <TheSun />
          </div>
          <div className={classnames(styles.AsSeenIn__logo, "is-inline-block")}>
            <NowThis />
          </div>
          <div className={classnames(styles.AsSeenIn__logo, "is-inline-block")}>
            <Stuff />
          </div>
        </div>
      </Container>
    </Section>
  )
}

export default AsSeenIn
