import React from "react"
import classnames from "classnames"

import { useTranslation } from "@hooks"

import Message from "react-bulma-components/lib/components/message"

import styles from "./styles.module.scss"

const CovidPromise = () => {
  const [t] = useTranslation()
  return (
    <Message className={styles.CovidPromise} marginless>
      <Message.Body
        className={classnames(
          styles.CovidPromise__body,
          "has-background-white-ter",
          "has-text-centered"
        )}
        borderless
      >
        <div
          dangerouslySetInnerHTML={{
            __html: t("components.CovidPromise.body", {
              interpolation: { escapeValue: false },
            }),
          }}
        />
      </Message.Body>
    </Message>
  )
}

export default CovidPromise
